<template>
  <v-card flat>
    <v-card-title>
      <menu-title title="Announcements"> </menu-title>
    </v-card-title>
    <v-card-text>
      <h2 v-if="getNotExpiredAnnouncements.length <= 0">
        No current Announcements
      </h2>
      <div v-else>
        <v-card
        class="mb-3"
        v-for="announcement in getNotExpiredAnnouncements"
        :key="announcement.id"
      >
        <v-card-title>
          <v-row>
            <v-col>
              {{ announcement.announcementtitle }}
            </v-col>
            <v-col :align="'center'">
              {{ getTopicById(announcement.topicid).topictype }}
            </v-col>
            <v-col :align="'center'">
              From
              {{ announcement.creationdate.substring(0, 10) }} - until
              {{ announcement.expirationdate.substring(0, 10) }}
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              {{ announcement.announcementtext }}
            </v-col>
            <v-col v-if="announcement.image" cols="3">
              <announcement-image :imageData="announcement.image.data">
              </announcement-image>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>
  
  <script>
import menuTitle from "../../components/helpers/menu_title.vue";
import customerItem from "../../components/user/customers_list_item.vue";
import customerDialog from "../../components/user/customer_dialog.vue";
import announcementImage from "../../components/admin/announcements/view/announcementimage.vue";

import { mapGetters, mapActions } from "vuex";
export default {
  name: "customers",
  components: {
    menuTitle,
    customerItem,
    customerDialog,
    announcementImage,
  },
  computed: {
    ...mapGetters({
      getNotExpiredAnnouncements: "getNotExpiredAnnouncements",
      getTopicById: "getTopicById",
    }),
  },
  methods: {
    ...mapActions({
      fetchTopics: "fetchTopics",
      fetchAllAnnouncements: "fetchAllAnnouncements",
    }),
  },
  async mounted() {
    this.fetchTopics();
    this.fetchAllAnnouncements();
  },
};
</script>
  
<style scoped>
</style>